import type { NextPage } from 'next';
import NextErrorComponent from 'next/error';
import type { ErrorProps } from 'next/error';

import { captureException, flush } from '@sentry/nextjs';

interface CyberErrorProps extends ErrorProps {
  err?: Error;
  hasGetInitialPropsRun?: boolean;
}

const CyberError: NextPage<CyberErrorProps> = ({ hasGetInitialPropsRun, err, statusCode }: CyberErrorProps) => {
  if (!hasGetInitialPropsRun && err) {
    captureException(err);
  }

  return <NextErrorComponent statusCode={statusCode} />;
};

CyberError.getInitialProps = async (ctx) => {
  const errorInitialProps: CyberErrorProps = await NextErrorComponent.getInitialProps(ctx);
  errorInitialProps.hasGetInitialPropsRun = true;
  if (ctx.err) {
    captureException(ctx.err);
    await flush(2000);
    return errorInitialProps;
  }
  captureException(new Error(`_error.tsx getInitialProps missing data at path: ${ctx.asPath}`));
  await flush(2000);

  return errorInitialProps;
};

export default CyberError;
